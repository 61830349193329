<template>
  <aw-modal
    :opened="opened"
    size="lg"
    :closable="closable"
    :modal-title="$awt('gdpr_modal_text_content_title')"
  >
    <template #content>
      <aw-static-modal-content
        v-if="!decline"
        ref="staticModalContent1"
        content-end-point-url="/static_content_pages/eula"
      >
        <template #contentTop>
          <lv-paragraph>
            <span v-html="$awt('gdpr.content_page_helptext')" />
          </lv-paragraph>
        </template>
        <template #contentBottom>
          <lv-paragraph>
            <span v-html="$awt('gdpr_page_description')" />
          </lv-paragraph>
        </template>

        <template #actionButtons>
          <lv-grid
            type="row"
            gutter="xs"
            wrap="wrap"
            justify-content="center"
            :class="$style.buttonGridRow"
          >
            <lv-grid
              type="item"
              xs="1/1"
              md="min"
              :class="$style.buttonGridItem"
            >
              <lv-button
                type="button"
                styling="primary-dark"
                v-bind="btnProps"
                @click="accept()"
              >
                {{ $awt('aw.global.accept') }}
              </lv-button>
            </lv-grid>
            <lv-grid
              type="item"
              xs="1/1"
              md="min"
              :class="$style.buttonGridItem"
            >
              <lv-button
                type="button"
                styling="secondary-dark"
                v-bind="btnProps"
                @click="decline = true"
              >
                {{ $awt('aw.gdpr.confirm_modal.cancel') }}
              </lv-button>
            </lv-grid>
          </lv-grid>
        </template>
      </aw-static-modal-content>
      <aw-static-modal-content
        v-else
        ref="staticModalContent2"
      >
        <template #contentTop>
          <lv-paragraph>{{ $awt('aw.gdpr.confirm_modal.content_text') }}</lv-paragraph>
        </template>
        <template #actionButtons>
          <lv-grid
            type="row"
            gutter="xs"
            wrap="wrap"
            justify-content="center"
            :class="$style.buttonGridRow"
          >
            <lv-grid
              type="item"
              xs="1/1"
              md="min"
              :class="$style.buttonGridItem"
            >
              <lv-button
                type="button"
                styling="secondary-dark"
                v-bind="btnProps"
                @click="decline = false"
              >
                {{ $awt('aw.gdpr.confirm_modal.step_back') }}
              </lv-button>
            </lv-grid>
            <lv-grid
              type="item"
              xs="1/1"
              md="min"
              :class="$style.buttonGridItem"
            >
              <lv-button
                type="button"
                styling="secondary-dark"
                v-bind="btnProps"
                @click="logout()"
              >
                {{ $awt('aw.gdpr.confirm_modal.logout') }}
              </lv-button>
            </lv-grid>
            <lv-grid
              type="item"
              xs="1/1"
              md="min"
              :class="$style.buttonGridItem"
            >
              <lv-button
                type="button"
                styling="secondary"
                v-bind="btnProps"
                @click="accountDeactivate()"
              >
                {{ $awt('aw.gdpr.confirm_modal.account_deactivate_button') }}
              </lv-button>
            </lv-grid>
          </lv-grid>
        </template>
      </aw-static-modal-content>

      <iframe
        v-if="showIframe"
        :src="customerDiamondLogoutUrl"
        width="0"
        height="0"
      />
    </template>
  </aw-modal>
</template>

<script>
  import { mapState } from 'pinia';
  import { useRuntimeConfig } from 'nuxt/app';
  import { LvParagraph, LvButton, LvGrid } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { M_GDPR } from '~~/common/config/Modal.js';
  import AwStaticModalContent from '~~/common/components/Common/Modals/AwStaticModalContent';
  import AwModal from '~~/common/components/Common/AwModal';

  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useAuthenticationStore } from '~~/common/stores/authentication';
  import { useConfigStore } from '~~/common/stores/config';
  import { useUserStore } from '~~/common/stores/user';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { useGdprStore } from '~~/shop/stores/gdpr';

  export default {
    name: 'AwGdprAcceptanceModal',
    components: {
      AwModal,
      AwStaticModalContent,
      LvParagraph,
      LvGrid,
      LvButton,
    },
    data () {
      return {
        isMounted: false,
        showIframe: false,
        decline: false,
      };
    },
    computed: {
      ...mapState(useModalStore, {
        modal: state => state.activeGenericModal,
      }),
      ...mapState(useOrderStore, {
        order: state => state.order,
      }),
      ...mapState(useConfigStore, {
        isSalesForceLogin: state => state.isSalesForceLogin,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useUserStore, {
        isEcomUser: state => state.isEcomUser,
      }),
      opened () {
        return this.modal.type === M_GDPR;
      },
      closable () {
        return this.modal.item.closable;
      },
      customerDiamondLogoutUrl () {
        return this.$awRuntimeConfig.customerDiamondLogoutUrl;
      },
      btnProps () {
        const shrink = !this.isMounted || this.screenRange['tablet-min'];
        return {
          shrink,
          stretch: !shrink,
        };
      },
    },
    mounted () {
      this.isMounted = true;
    },
    methods: {
      async accountDeactivate () {
        if (this.isSalesForceLogin) {
          window.location = this.$awRuntimeConfig.customerDiamondAccountDeleteUrl;
        } else {
          await this.$api.$put('/me/deactivate');
        }
        await this.logout();
      },
      async logout () {
        const modalStore = useModalStore();
        const orderStore = useOrderStore();
        const authenticationStore = useAuthenticationStore();
        const config = useRuntimeConfig();

        const deliveryStore = useDeliveryStore();
        this.$refs.staticModalContent2.showSpinner = true;
        if (this.isSalesForceLogin) {
          this.showIframe = true;
        }
        await authenticationStore.logout();
        await orderStore.fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });
        await orderStore.fetchOrderModification();

        if (config.public.isShop) {
          await deliveryStore.fetchMethod();
          await deliveryStore.fetchTimeframes();
        }
        modalStore.closeGdprAcceptanceModal();
      },
      async accept () {
        const userStore = useUserStore();
        const gdprStore = useGdprStore();
        const modalStore = useModalStore();
        const orderStore = useOrderStore();
        this.$refs.staticModalContent1.showSpinner = true;
        await gdprStore.updateAcceptance();
        modalStore.closeGdprAcceptanceModal();

        if (this.isSalesForceLogin && !userStore?.data?.personalInfo?.birthDate) {
          modalStore.openProfileModal();
        } else if (this.isEcomUser && this.order.mergeNeeded) {
          await orderStore.fetchCartMerge();
          modalStore.openCartMergeModal();
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.buttonGridRow {
  .buttonGridItem {
    @include sm(max) {
      padding-bottom: 8px !important;

      &:last-child {
        padding-bottom: 0 !important;
      }
    }
  }
}
</style>
